import React from "react"
import GliderComponent from "react-glider-carousel"

const Glider = ({ id, customizedSettings, noResponsive, children, ...props }) => {
  const settings = {
    draggable: true,
    slidesToShow: 2.4,
    slidesToScroll: 1,
    rewind: true,
    responsive: !noResponsive
      ? [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
        ]
      : null,
    ...customizedSettings,
  }
  return (
    <GliderComponent
      id={id}
      hasArrows={props.hasArrows}
      hasDots={props.hasDots}
      arrows={props.arrows}
      settings={settings}
    >
      {children}
    </GliderComponent>
  )
}

export default Glider
