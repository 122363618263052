import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useCore } from "../../../hooks/useCore"
import { useShopify } from "../../../hooks/useShopify"

export const withFeaturedProductCarouselSection = Component => ({ name = "FeaturedProductCarouselSection", products, productHandles, ...props }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const {
    graphql: {
      queries: { GET_PRODUCTS_BY_HANDLE },
    },
  } = useCore()

  const { useQuery, productNormaliser } = useShopify()

  let formatted = false
  let mappedProducts = {}

  const { data, loading, error } =
    typeof window !== `undefined` && productHandles.length > 0
      ? useQuery(GET_PRODUCTS_BY_HANDLE(productHandles), {
          fetchPolicy: `cache-and-network`,
          variables: {
            firstCollections: 5,
            firstImages: 1,
            firstMetafields: 1,
            firstVariants: 1,
          },
        })
      : { data: false, loading: false, error: false }

  if (error) console.error(error)

  if (!loading && data && !formatted) {
    mappedProducts = products.map(product => {
      const key = product.handle.replace(/-/g, "")
      const mappedProduct = data[key]
      const normalisedProduct = productNormaliser(mappedProduct)
      return {
        image: normalisedProduct?.images[0],
        link: `${routes?.PRODUCT}/${product.handle}`,
        title: normalisedProduct?.title.split(" ")[0],
        subTitle: normalisedProduct?.title.split(" ")[1],
        defaultVariant: normalisedProduct?.variants[0],
      }
    })
    formatted = true
  }

  if (formatted) {
    Component.displayName = name
    return <Component products={mappedProducts} {...props} />
  } else {
    return null
  }
}
