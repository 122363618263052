import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link, navigate } from "gatsby"

import Image from "../../Image/Image"
import { StyledButton } from "../../Styled/Button"
import Price from "../../Price/Price"

const Wrapper = styled.div`
  ${tw`pt-1-6 px-0-8 md:p-1-6 pb-0-8`}
`

const ShadowWrapper = tw.div`
  relative shadow-none group-hover:shadow-card pb-4-8 md:pb-0
`

const Desktop = tw.div`
  absolute left-0-8 bottom-0-8 inset-0 text-center bg-lightest hidden md:flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto transform transition-all duration-slow
`

const Mobile = styled(Link)`
  ${tw`block md:hidden z-10 absolute inset-0 select-none`}
`

const Details = tw.div`
  absolute bottom-0-8 left-0-8
`

const Title = tw.h3`
  text-14 leading-1.29 font-medium md:text-26 md:leading-1.62 md:font-display mb-0-5 md:pt-0-8
`

const SubTitle = tw.p`
  block text-16 leading-1.31 mb-1-2
`

const Button = styled(StyledButton)`
  ${tw`flex max-w-14-4 lg:max-w-16-8 w-full select-none`}
`

const StyledPrice = styled(Price)`
  ${tw`text-14 leading-1.29 sm:text-16 sm:leading-1.31 sm:mb-1-6`}
`

const FeaturedProductCarouselCard = ({ image, link, title, subTitle, defaultVariant }) => (
  <Wrapper className={`group`}>
    <ShadowWrapper>
      <Image image={image.src} alt={image.alt ? image.alt : `${title} ${subTitle}`} ratio={`12/13`} />
      <Desktop>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <StyledPrice price={defaultVariant.priceV2} compareAtPrice={defaultVariant.compareAtPriceV2} />
        <Button height={`48-32`} textSize={`14`} colour={`lightest`} onClick={() => navigate(link)} border>
          Shop Now
        </Button>
      </Desktop>
      <Mobile to={link}>
        <Details>
          <Title>{title}</Title>
          <StyledPrice price={defaultVariant.priceV2} compareAtPrice={defaultVariant.compareAtPriceV2} />
        </Details>
      </Mobile>
    </ShadowWrapper>
  </Wrapper>
)

export const FeaturedProductCarouselCardPropTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  defaultVariant: PropTypes.object,
}

FeaturedProductCarouselCard.propTypes = FeaturedProductCarouselCardPropTypes

export default FeaturedProductCarouselCard
