import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Icon from "../../Icon"
import Glider from "../../Glider"

const StyledArrow = styled.div`
  ${tw`hidden md:block absolute top-1/2 text-dark hover:opacity-75 cursor-pointer`}
  ${({ direction }) => (direction === "prev" ? tw`transform rotate-180 left-0` : tw`right-0`)}
`

const SliderArrow = ({ id, direction }) => (
  <StyledArrow id={`${direction}-${id}`} direction={direction}>
    <Icon name={`vintageArrow`} />
  </StyledArrow>
)

const FeaturedProductCarouselSlider = ({ defaultDots, id, children }) => {
  const prevArrowSelector = `#prev-${id}`
  const nextArrowSelector = `#next-${id}`
  const hasDots = window.innerWidth < 415 ? true : defaultDots
  return (
    <>
      <SliderArrow id={id} direction={`prev`} />
      <SliderArrow id={id} direction={`next`} />
      <Glider
        hasArrows
        hasDots={hasDots}
        arrows={{
          prev: prevArrowSelector,
          next: nextArrowSelector,
        }}
      >
        {children}
      </Glider>
    </>
  )
}

export default FeaturedProductCarouselSlider
