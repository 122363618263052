import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withFeaturedProductCarousel } from "./withFeaturedProductCarousel"
import FeaturedProductCarouselSection from "./FeaturedProductCarouselSection"
import { StyledContainer, BackgroundContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${tw`pt-3-2 md:pt-6-4 pb-5-6 md:pb-4 px-0-8 md:px-7-2 max-w-xl mx-auto`}
`

const Title = tw.h2`
  mb-0-8 font-display text-28 md:text-44 leading-1.29 md:leading-1.45 text-center
`

const TabsWrapper = tw.div`
  flex md:hidden flex-row w-1/2 mx-auto justify-around  
`

const TabsContent = styled.p`
  ${tw`text-16 leading-1.75 mt-0-8 mb-1-6`}
  ${({ active, index }) => (active === index ? tw`border-b` : null)}
`

const Tabs = ({ active, setActive, items }) => {
  return (
    <TabsWrapper>
      {items.map((item, index) => {
        return (
          <TabsContent key={index} active={active} index={index} onClick={() => setActive(index)}>
            {item}
          </TabsContent>
        )
      })}
    </TabsWrapper>
  )
}

const Desktop = tw.div`
  hidden md:block
`

const Mobile = tw.div`
  md:hidden
`

const FeaturedProductCarousel = withFeaturedProductCarousel(({ title, background, sections }) => {
  const [active, setActive] = useState(0)
  const sectionTitles = sections ? sections.map(section => section.title) : []
  return (
    <BackgroundContainer background={background}>
      {sections && (
        <Container width={`full`}>
          {title && <Title>{title}</Title>}
          <Mobile>
            {sections.length > 1 && <Tabs active={active} setActive={setActive} items={sectionTitles} />}
            <FeaturedProductCarouselSection
              defaultDots={sections.length === 1}
              id={`mobile`}
              title={sections[active].title}
              link={sections[active].link}
              products={sections[active].products}
              productHandles={sections[active].productHandles}
            />
          </Mobile>
          <Desktop>
            {sections.map((section, index) => (
              <FeaturedProductCarouselSection
                key={`${section.title}-${index}`}
                defaultDots={sections.length === 1}
                id={`desktop-${index}`}
                title={section.title}
                link={section.link}
                products={section.products}
                productHandles={section.productHandles}
              />
            ))}
          </Desktop>
        </Container>
      )}
    </BackgroundContainer>
  )
})

export const FeaturedProductCarouselPropTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      products: PropTypes.array,
    })
  ).isRequired,
}

FeaturedProductCarousel.propTypes = FeaturedProductCarouselPropTypes

FeaturedProductCarousel.defaultProps = {
  background: "lightest",
}

export default FeaturedProductCarousel
