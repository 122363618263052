import React from "react"

export const withFeaturedProductCarousel = Component => ({ name = "FeaturedProductCarousel", sections, ...props }) => {
  const mappedSections = sections?.map(section => ({
    title: section?.buttonLink?.title,
    link: section?.buttonLink?.link,
    products: section?.products?.map(product => ({
      id: product?.shopify?.shopifyId,
      handle: product?.shopify?.shopifyHandle,
    })),
    productHandles: section?.products?.map(product => product?.shopify?.shopifyHandle),
  }))

  Component.displayName = name
  return <Component {...props} sections={mappedSections} />
}
