import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withFeaturedProductCarouselSection } from "./withFeaturedProductCarouselSection"
import { isBrowser } from "../../../utils/domUtils"
import FeaturedProductCarouselCard from "./FeaturedProductCarouselCard"
import FeaturedProductCarouselSlider from "./FeaturedProductCarouselSlider"

const Wrapper = styled.div`
  ${tw`relative md:px-7-2 md:mb-3-2`}
`

const Title = tw.div`
  hidden md:block mb-1-6 text-22 leading-2.09 text-center select-none
`

const StyledLink = styled(Link)`
  ${tw`hidden md:block absolute right-0 top-1 text-16 leading-1.5 border-b hover:text-orange hover:border-orange`}
`

const FeaturedProductCarouselSection = withFeaturedProductCarouselSection(({ defaultDots, id, title, link, products }) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {link?.length > 0 && <StyledLink to={link}>See all</StyledLink>}
      {isBrowser && (
        <FeaturedProductCarouselSlider id={id} defaultDots={defaultDots}>
          {products?.map((product, index) => (
            <FeaturedProductCarouselCard
              key={index}
              image={product.image}
              link={product.link}
              title={product.title}
              subTitle={product.subTitle}
              defaultVariant={product.defaultVariant}
            />
          ))}
        </FeaturedProductCarouselSlider>
      )}
    </Wrapper>
  )
})

export const FeaturedProductCarouselSectionPropTypes = {
  defaultDots: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  link: PropTypes.string,
  products: PropTypes.array,
}

FeaturedProductCarouselSection.propTypes = FeaturedProductCarouselSectionPropTypes

export default FeaturedProductCarouselSection
